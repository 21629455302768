<template>
  <div class="md-nav-class-component">
    <div class="action-box" v-if="!userInfo || isipLogin">
      <a href="javascript:;" @click="toPage('/login')">登录</a> <span>|</span
      ><a href="javascript:;" @click="toPage('/register')">注册</a>
    </div>
    <div class="user-box" v-else>
      <div class="user-center">
        <div class="avatar" @click="toPages">
          <img
            v-if="userInfo?.avatar"
            :src="`/video-dev${userInfo?.avatar}`"
            alt=""
          />
          <img v-else src="@/assets/img/avatar.jpeg" alt="" />
          {{
            userInfo.userType == "01"
              ? userInfo.dept.deptName
              : userInfo.nickName
          }}
        </div>
        <div class="user-center-panel">
          <ul class="u-list">
            <li class="u-item" @click="toPage('/manage/guankanjilu')">
              我的课程
            </li>
            <li class="u-item" @click="toPage('/manage/shoucangjilu')">
              我的收藏
            </li>
            <li class="u-item" @click="toPage('/manage/datijilu')">答题记录</li>
            <li class="u-item" @click="toPage('/manage/zhengshu')">酷学证书</li>
            <li class="u-item" @click="toPage('/manage/gerenziliao')">
              个人信息
            </li>
            <li class="u-item-loginout" @click="handleLogout" v-if="type !== 3">
              退出登录
            </li>
            <li class="u-item-loginout" @click="handleLogout" v-else>
              <a
                href="https://cas.swcvc.edu.cn/cas/logout?service=https://www.aikoolearn.com/cas"
                >退出登录</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // userInfo: {},
      type: null,
      isLogin: false,
      userInfo: null,
    };
  },
  created() {
    // this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    // console.log(this.userInfo, 'userinfo');
    this.type = localStorage.getItem("logintype") || 1;
  },
  computed: {
    isipLogin() {
      return window.localStorage.getItem("isipLogin");
    },
  },
  mounted() {
    this.isLogin = window.localStorage.getItem("isLogin");
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));

    window.addEventListener("setItemEvent", (e) => {
      if (e.key == "userInfo") {
        this.userInfo = JSON.parse(e.newValue);
      }
      if (e.key == "isLogin") {
        this.isLogin = e.newValue;
      }
    });
  },
  methods: {
    toPage(path) {
      this.$router.push(path);
    },
    toPages() {
      if (this.isipLogin) {
        this.$router.push("/register");
      } else {
        this.$router.push("/manage");
      }
    },
    handleLogout() {
      this.$store.state.isLogin = false;
      this.$store.state.user = null;
      localStorage.clear();
      window.location.href = "/login";
    },
  },
};
</script>
<style lang="less" scoped>
.md-nav-class-component {
  .action-box {
    margin-left: 20px;
    font-size: 15px;
    width: 96px;
    height: 38px;
    border-radius: 20px;
    color: @md-primary-color;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: @md-primary-color;
      margin-left: 3px;
      margin-right: 3px;
    }

    a {
      font-size: 15px;
      color: @md-primary-color;
      cursor: pointer;

      &:hover {
        color: @md-primary-color;
      }
    }
  }

  .user-box {
    margin-left: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
      color: #545c63;
      cursor: pointer;

      &:hover {
        color: @md-primary-color;
      }
    }

    .user-center {
      margin-left: 10px;
      position: relative;
      padding: 20px 0;

      &:hover {
        .user-center-panel {
          display: block;
        }

        // .avatar {
        //   border: 2px solid @md-primary-color;
        // }
      }

      .avatar {
        display: inline-block;
        height: 32px;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-left: 10px;
        }
      }

      .user-center-panel {
        position: absolute;
        top: 65px;
        /* 将元素的顶部边缘定位到父元素的中心 */
        left: 50%;
        /* 将元素的左边边缘定位到父元素的中心 */
        transform: translate(-50%, 0);
        z-index: 999;
        width: 120px;
        display: none;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 16px 0px rgba(15, 176, 155, 0.1);
        box-sizing: border-box;

        .u-box {
          display: flex;
          align-items: center;

          .ava-box {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            border: 2px solid #fff;
            margin-right: 12px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          span {
            color: #07111b;
            font-size: 16px;
          }
        }

        .u-list {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .u-item-loginout {
            border: 1px solid #ebebeb;
          }

          .u-item-loginout {
            width: 120px;
            height: 41px;
            font-size: 14px;
            background: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            color: #1c1f21;

            i {
              margin-right: 6px;
              font-size: 16px;

              &:hover {
                color: #545c63;
              }
            }

            &:hover {
              background: #e2f5f3;
              color: @md-primary-color;
            }
          }

          .u-item {
            width: 120px;
            height: 34px;
            font-size: 14px;
            background: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            color: #1c1f21;

            i {
              margin-right: 6px;
              font-size: 16px;

              &:hover {
                color: #545c63;
              }
            }

            &:hover {
              background: @md-light-primary-color;
              color: @md-primary-color;
            }
          }
        }
      }
    }
  }
}
</style>
