import axios from 'axios'
export const getCategoryList = params => axios.get(`/web/category/getCategoryList?type=${params}`) // 获取分类列表
export const getVideoListByCategoryId = params => axios.get(`/web/category/getVideoListByCategoryId`, {
        params: {...params }
    }) // 根据分类id获取课程列表
    export const getVideoInfo = (rid, userId,sysVideoId) => axios.get(`/web/category/getVideoInfo?rid=${rid}&userId=${userId}&sysVideoId=${sysVideoId}`) // 根据分类id获取课程列表
export const getSelectVideoOutlineDetailList = params => axios.get(`/web/category/selectVideoOutlineDetailList?videoId=${params}`) // 根据分类id获取课程列表

export const getvdeosList = params => axios.get(`/biz/detail/cid/${params}`) // 获取课程列表视频


// 观看历史
export const addUserView = params => axios.post('/web/userview/addUserView', params) // 收藏/取消收藏

    /**
     * 收藏相关
     */
export const toggleCollect = params => axios.post('/web/usercollect/toggleCollect', params) // 收藏/取消收藏
export const addUserVideoLike = params => axios.get(`/web/uservideolike/addUserVideoLike?videoId=${params.videoId}`) // 收藏/取消收藏

export const addUserDuration = params => axios.get(`/web/userview/addUserDuration?timeStamp=${params.timeStamp}`,) // 观看视频期间每一分钟报一次