var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-video-pages-component"},[_c('MdDetailHeaderClassComponent',{attrs:{"title":_vm.videoTit}}),_c('div',{staticClass:"md-play-container"},[_c('div',{staticClass:"md-video-panel"},[_c('div',{staticClass:"course-sidebar-layout"},[_c('dl',[_c('dd',[(!_vm.isCollect)?_c('span',{on:{"click":function($event){return _vm.handleCollect('on')}}},[_c('i',{staticClass:"el-icon-star-off c-star"}),_vm._v("收藏")]):_c('span',{staticStyle:{"color":"#25c0a7"},on:{"click":function($event){return _vm.handleCollect('off')}}},[_c('i',{staticClass:"el-icon-star-on c-star",staticStyle:{"color":"#25c0a7"}}),_vm._v("取消收藏")])]),_c('dd',[(!_vm.isLike)?_c('span',{on:{"click":function($event){return _vm.handleLike('on')}}},[_c('i',{staticClass:"el-icon-thumb c-star"}),_vm._v("点赞")]):_c('span',{staticStyle:{"color":"#25c0a7"},on:{"click":function($event){return _vm.handleLike('off')}}},[_c('i',{staticClass:"el-icon-thumb c-star",staticStyle:{"color":"#25c0a7"}}),_vm._v("取消点赞")])])])]),(!_vm.isShowvideo)?_c('div',{staticClass:"video-con"},[_c('div',{staticClass:"video-container"},[_c('meta',{attrs:{"http-equiv":"Content-Security-Policy","content":"upgrade-insecure-requests"}}),_c('iframe',{attrs:{"src":_vm.videoUrl,"frameborder":"0","scrolling":"no","allowfullscreen":"","name":"processResultMsg"}})])]):_c('div',{staticClass:"videos"},[_c('div',{staticClass:"video-container"},[_c('video',{key:_vm.videoUrl,ref:"videoDOM",attrs:{"width":"100%","height":"100%","controls":"","controlsList":"nodownload","autoplay":"","playbackRate":_vm.playbackRate}},[_c('source',{attrs:{"src":_vm.videoUrl,"type":"video/mp4"}})])])]),_c('div',{staticClass:"draw-box",class:{
          'draw-box': true,
          'less-width': _vm.videoUrl.includes('jinbiaochi'),
        }},[_c('b',[_vm._v("1.课程大纲")]),_c('div',{staticClass:"content"},_vm._l((_vm.videoList),function(item,index){return _c('div',{key:index,staticClass:"content-item",class:{
              'content-item': true,
              'orange-background': _vm.videoUrl.includes(item.url),
            },on:{"click":function($event){return _vm.checkUrl(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])]),_c('el-dialog',{attrs:{"title":"我要评论","visible":_vm.dialogVisible,"close-on-click-modal":false,"close-on-press-escape":false,"width":"40%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-input',{attrs:{"type":"textarea","maxlength":"300","show-word-limit":"","autosize":{ minRows: 5, maxRows: 5 },"placeholder":"请输入内容"},model:{value:(_vm.textareaVal),callback:function ($$v) {_vm.textareaVal=$$v},expression:"textareaVal"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("发表评论")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }