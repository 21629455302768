<template>
  <div class="md-video-pages-component">
    <!-- 头部 -->
    <MdDetailHeaderClassComponent :title="videoTit" />
    <div class="md-play-container">
      <!-- 播放器 -->
      <div class="md-video-panel">
        <div class="course-sidebar-layout">
          <dl>
            <dd>
              <!-- <span>收藏</span> -->
              <span v-if="!isCollect" @click="handleCollect('on')"
                ><i class="el-icon-star-off c-star"></i>收藏</span
              >
              <span v-else @click="handleCollect('off')" style="color: #25c0a7"
                ><i class="el-icon-star-on c-star" style="color: #25c0a7"></i
                >取消收藏</span
              >
            </dd>
            <dd>
              <span v-if="!isLike" @click="handleLike('on')"
                ><i class="el-icon-thumb c-star"></i>点赞</span
              >
              <span v-else @click="handleLike('off')" style="color: #25c0a7"
                ><i class="el-icon-thumb c-star" style="color: #25c0a7"></i
                >取消点赞</span
              >
              <!-- <i class="el-icon-thumb"></i>
              <span>点赞</span> -->
            </dd>
            <!-- <dd @click="dialogVisible = true">
              <i class="el-icon-chat-square"></i>
              <span>评论</span>
            </dd> -->
            <!-- <dd class="view-nice">
              <span>点赞次数</span>
              <span>333</span>
            </dd>
            <dd class="view-person">
              <span>观看人次</span>
              <span>333</span>
            </dd> -->
          </dl>
        </div>
        <!-- <div class="video-con" :style="{ 'width': drawFlag ? 'calc(100% - 160px)' : 'calc(100% - 160px)' }"> -->
        <div class="video-con" v-if="!isShowvideo">
          <div class="video-container">
            <!-- <div class="icon"></div> -->
            <meta
              http-equiv="Content-Security-Policy"
              content="upgrade-insecure-requests"
            />
            <iframe
              :src="videoUrl"
              frameborder="0"
              scrolling="no"
              allowfullscreen
              name="processResultMsg"
            ></iframe>
          </div>
        </div>
        <div v-else class="videos">
          <div class="video-container">
            <!-- 播放器 -->
            <video
              ref="videoDOM"
              width="100%"
              height="100%"
              :key="videoUrl"
              controls
              controlsList="nodownload"
              autoplay
              :playbackRate="playbackRate"
            >
              <source :src="videoUrl" type="video/mp4" />
            </video>
          </div>
        </div>
        <div
          class="draw-box"
          :class="{
            'draw-box': true,
            'less-width': videoUrl.includes('jinbiaochi'),
          }"
        >
          <b>1.课程大纲</b>

          <div class="content">
            <div
              class="content-item"
              :class="{
                'content-item': true,
                'orange-background': videoUrl.includes(item.url),
              }"
              v-for="(item, index) in videoList"
              :key="index"
              @click="checkUrl(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评论/推荐课程 -->
    <!-- <div class="comment-intro-box">
      <div class="tab-box">
        <ul class="tab-block">
          <li class="tab-item">评论</li>
        </ul>
      </div>
      <div class="comment-intro-block">
        <div class="comment-box">
          <div class="c-head">
            <div class="c-tag">全部</div>
            <div class="c-send-btn" @click="dialogVisible = true">我要发布</div>
          </div>
          <div v-if="commentList && commentList.length">
            <ul class="commit-list">
              <li class="commit-item" v-for="(item, index) in commentList" :key="index">
                <div class="avatar">
                  <img :src="item.avatar" alt="">
                </div>
                <div class="r-box">
                  <div class="tit-box">
                    <p class="name">{{ item.nickname }}</p>
                    <span class="time">{{item.datetime}}</span>
                  </div>
                  <p class="tit">{{ item.commit }}</p>
                </div>
              </li>
            </ul>
            <div class="pagination">
              <el-pagination @current-change="handleCurrentChange" :current-page="+params.page" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
          <div class="no-more-data" v-else>
            <i class="el-icon-warning"></i>
            <span>目前暂无任何评论</span>
          </div>
        </div>
        <div class="intro-box">
          <b>推荐课程</b>
          <ul>
            <li v-for="item in 5" :key="item">
              <div class="c-img">
                <img src="../assets/img/s1.jpeg" alt="">
              </div>
              <div class="c-type-box">
                <p class="c-tit">高并发/高性能 Go语言开发企业级抽奖项目</p>
                <p class="view-p-count">
                  <i class="el-icon-user"></i>
                  <span>1403</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <el-dialog
      title="我要评论"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
    >
      <el-input
        type="textarea"
        maxlength="300"
        show-word-limit
        :autosize="{ minRows: 5, maxRows: 5 }"
        placeholder="请输入内容"
        v-model="textareaVal"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >发表评论</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getVideoInfo,
  toggleCollect,
  getSelectVideoOutlineDetailList,
  addUserVideoLike,
  getvdeosList,
  addUserDuration,
} from "@/axios/course";
import { Loading } from "element-ui";
import MdDetailHeaderClassComponent from "../components/class/mdDetailHeader/index.vue";
export default {
  name: "mdVideoPagesComponent",
  components: {
    MdDetailHeaderClassComponent,
  },
  data() {
    return {
      videoTit: "",
      outlineId: "",
      playbackRate: 1, // 播放倍速 1
      isCollect: false,
      isLike: false,
      drawFlag: true,
      dialogVisible: false,
      textareaVal: "",
      commentList: [],
      params: {
        page: 1,
      },
      total: 0,
      videoInfo: {},
      videoUrl: "",
      curVideoIndex: 0, // 当前播放视频索引
      videoRef: null,
      timer: null,
      isShowvideo: false,
      videoList: [],
      url: "",
    };
  },
  watch: {
    // '$route.query': {
    //   handler() {
    //     this.$nextTick(() => {
    //       // 自动播放下一个视频
    //       if (sessionStorage.getItem('curVideoIndex')) {
    //         this.curVideoIndex = sessionStorage.getItem('curVideoIndex')
    //       }
    //       this.videoRef.addEventListener('ended', this.autoPlayNext)
    //     })
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  created() {
    this.timer = setInterval(() => {
      // 需要定时执行的代码
      this.getTime();
    }, 60 * 1000);

    this.$store.state.fixed = false; // 注意：直接修改store的state通常不是最佳实践，建议使用mutations

    let url = this.$route.query.vUrl;

    if (url) {
      this.getVideoList(this.$route.query.rid);
      this.processVideoUrl(url); // 使用辅助函数处理URL
    } else {
      this.getVideo(this.$route.query.rid);
    }
  },

  mounted() {
    this.videoRef = this.$refs.videoDOM;
  },

  methods: {
    addZero(num) {
      return num < 10 ? "0" + num : num; // 如果数值不足两位则添加前导零
    },
    getTime() {
      var currentTime = new Date(); // 创建一个表示当前时间的Date对象

      // 将年、月、日、小时、分钟和秒转换成两位数字并连接起来形成所需的格式
      var formattedTime =
        currentTime.getFullYear() +
        "-" +
        (currentTime.getMonth() + 1) +
        "-" +
        currentTime.getDate() +
        " " +
        this.addZero(currentTime.getHours()) +
        ":" +
        this.addZero(currentTime.getMinutes()) +
        ":" +
        this.addZero(currentTime.getSeconds());
      console.log(formattedTime, "ujy"); // 输出结果：2023-05-26 14:30:45（根据当前时间而定）;

      this.addUserDurationData({ timeStamp: formattedTime });
    },
    //每一分钟调用一次

    async addUserDurationData(data) {
      const res = await addUserDuration(data);
      console.log(res);
    },
    checkUrl(item) {
      console.log(item, "item");
      this.url = item.url;
      if (item.url.includes("http")) {
        this.videoUrl = item.url;
      } else {
        this.videoUrl = `https://www.aikoolearn.com/video-dev${item.url}`;
      }

      this.videoRef.load();
      this.videoRef.play();
    },

    async getVideoInfo() {
      const rid = this.$route.query.rid || "";
      const sysVideoId = this.$route.query.sysVideoId || "";
      const res = await getVideoInfo(rid, null, sysVideoId);
      if (res.code === 200) {
        console.log(res, "res");
        this.isCollect = res.data.isCollect;
        this.isLike = res.data.isLike;
      }
    },
    async getSelectVideoOutlineDetailList(id) {
      const res = await getSelectVideoOutlineDetailList(id);
      if (res.code === 200) {
        this.videoInfo = res.data.vVideoOutlines[0];
        this.videoTit =
          this.videoInfo &&
          this.videoInfo?.videoOutineDetails &&
          this.videoInfo?.videoOutineDetails[0].name;
        this.isCollect = res.data.isCollect;
      }
    },
    async getVideo(id) {
      let loadingInstance1 = Loading.service();
      const res = await getvdeosList(id);
      loadingInstance1.close();
      if (res.code == 200) {
        this.videoList = res.rows;
        if (res.rows.length > 0) {
          const firstVideoUrl = res.rows[0]?.url;
          this.processVideoUrl(firstVideoUrl); // 使用辅助函数处理URL
        } else {
          this.$message.error("暂无视频,请选择其他课程学习");
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    processVideoUrl(url) {
      if (url.includes("mp4")) {
        this.isShowvideo = true;
        if (url.includes("http")) {
          this.videoUrl = url;
        } else {
          this.videoUrl = `https://www.coollearn.cn/video-dev${url}`;
        }
      } else {
        this.videoUrl = url; // 如果不是mp4，则直接使用原始URL
      }
    },

    async getVideoList(id) {
      const res = await getvdeosList(id);
      if (res.code == 200) {
        this.videoList = res.rows;
      } else {
        this.$message.error("暂无视频,请选择其他课程学习");
      }
    },
    // 自动播放下一个
    autoPlayNext() {
      if (this.videoInfo?.videoOutineDetails?.length) {
        if (
          this.curVideoIndex <
          this.videoInfo?.videoOutineDetails.length - 1
        ) {
          this.curVideoIndex++;
          const videoItem =
            this.videoInfo?.videoOutineDetails[this.curVideoIndex];
          this.videoTit = videoItem.name;
          this.$router.replace({
            path: "/video",
            query: {
              rid: this.$route.query.rid,
              outLineId: videoItem.rid,
              vUrl: videoItem.url,
            },
          });
          this.videoUrl = videoItem.url;
          this.videoRef.play();
        }
      }
    },
    // 点击课程大纲
    handleOutline(item, index) {
      this.videoTit = item.name;
      this.curVideoIndex = index;
      sessionStorage.setItem("curVideoIndex", this.curVideoIndex);
      this.$router.replace({
        path: "/video",
        query: {
          rid: this.$route.query.rid,
          outLineId: item.rid,
          vUrl: item.url,
        },
      });
      this.videoUrl = item.url;
      this.videoRef.load(); // 视频重新加载，避免继续播放上一个视频
      this.videoRef.play();
    },
    async handleCollect(type) {
      if (type === "on") {
        this.isCollect = true;
        const res = await toggleCollect({
          videoId: this.$route.query.rid,
        });
        if (res.code === 200) {
          this.$message.success("收藏成功");
        }
      } else {
        this.isCollect = false;
        const res = await toggleCollect({
          videoId: this.$route.query.rid,
        });
        if (res.code === 200) {
          this.$message.success("取消收藏");
        }
      }
    },
    async handleLike(type) {
      if (type === "on") {
        this.isLike = true;
        const res = await addUserVideoLike({
          videoId: this.courseDetailInfo.rid,
        });
        if (res.code === 200) {
          this.$message.success("点赞成功");
        }
      } else {
        this.isLike = false;
        const res = await addUserVideoLike({
          videoId: this.courseDetailInfo.rid,
        });
        if (res.code === 200) {
          this.$message.success("取消点赞");
        }
      }
    },
    handleNice() {
      this.$message.success("点赞成功");
    },
    handleCurrentChange(val) {
      this.params.page = val;
    },
  },
  beforeDestroy() {
    // this.$store.state.fixed = true
    // this.videoRef.removeEventListener('ended', this.autoPlayNext)
    // this.videoRef = null
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="less" scoped>
iframe {
  width: 100%;
  height: 100%;
}

.videos {
  width: calc(100% - 530px);
  height: 100%;
  background: #1c1f21;
  margin: 30px 0 0 100px;
  padding: 20px 0;
  box-sizing: border-box;
  overflow: hidden;
}
.orange-background {
  width: 90%;
  padding: 10px;
  background-color: #fe6b03;
  border-radius: 5px;
}

.content {
  overflow-y: scroll;
  height: calc(100% - 70px);
  margin-top: 15px;

  .content-item {
    color: #fff;
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
    width: 90%;
    border-radius: 5px;
    cursor: pointer;
  }
}

.md-video-pages-component {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;

  .md-play-container {
    width: 100%;
    background: #000;

    .md-video-panel {
      width: 100%;
      height: calc(100vh - 72px);
      position: relative;

      .course-sidebar-layout {
        position: absolute;
        top: 0;
        left: 0;
        background: #1c1f21;
        height: 100%;
        width: 100px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        z-index: 99;

        dl {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          dd {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
            cursor: pointer;
            color: #fff;
            position: relative;

            i {
              font-size: 24px;
              color: rgba(255, 255, 255, 0.6);
              margin-bottom: 2px;
            }

            span {
              color: rgba(255, 255, 255, 0.4);
              font-size: 14px;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }

          .view-nice,
          .view-person {
            position: absolute;
            bottom: 30px;
            left: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
            color: #fff;
            cursor: unset;

            span {
              font-size: 12px;
              color: #fff;
            }
          }

          .view-nice {
            bottom: 80px;

            span {
              color: @md-primary-color;
            }
          }
        }
      }

      .video-con {
        width: calc(100% - 100px);
        // width: 100%;
        height: 100%;
        background: #1c1f21;
        margin: 0 0 0 100px;
        padding: 20px 0;
        box-sizing: border-box;
        overflow: hidden;

        .video-container {
          position: relative;
          width: 98%;
          height: 100%;
          background: #000;
          margin: 18px 0px 0px 23px;

          .icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 70px;
            height: 60px;
            background-color: #1c1f21;
          }
        }
      }

      .draw-switch {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 60px;
        z-index: 99;
        box-sizing: border-box;
        background: #1c1f21;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        &:hover {
          color: @md-primary-color !important;

          i {
            color: @md-primary-color !important;
          }

          span {
            color: @md-primary-color !important;
          }
        }

        i {
          color: #93999f;
          font-size: 28px;
          margin-bottom: 3px;
        }

        span {
          color: #93999f;
          font-size: 14px;
        }
      }

      .draw-box {
        position: absolute;
        top: 0;
        right: 0;
        width: 440px;
        height: 100%;
        z-index: 99;
        box-sizing: border-box;
        background: #1c1f21;
        padding: 60px 40px 20px;

        .close-unit {
          position: absolute;
          top: 15px;
          right: 15px;
          padding: 5px;
          font-size: 24px;
          color: #93999f;
          cursor: pointer;

          &:hover {
            color: @md-primary-color;
          }
        }

        b {
          padding-bottom: 10px;
          color: @md-font-color;
        }

        ul {
          margin-top: 10px;
          height: calc(100% - 20px);
          box-sizing: border-box;
          overflow-y: auto;

          .active {
            .c-type-box {
              .c-tit {
                color: @md-primary-color;
              }
            }
          }

          li {
            display: flex;
            padding: 5px;
            border-bottom: 1px solid #545c63;

            &:hover {
              .c-type-box {
                .c-tit {
                  color: @md-primary-color;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            .c-img {
              width: 80px;
              height: 60px;
              border-radius: 6px;
              overflow: hidden;
              position: relative;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .c-type-box {
              flex: 1;
              margin-left: 10px;
              overflow: hidden;

              .c-tit {
                width: 100%;
                padding-top: 8px;
                font-size: 14px;
                margin-bottom: 4px;
                cursor: pointer;
                color: #93999f;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .view-p-count {
                display: flex;
                align-items: center;
                color: #545c63;
                font-size: 12px;
                margin-top: 5px;

                i {
                  margin-right: 3px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .comment-intro-box {
    width: 100%;

    .tab-box {
      width: 100%;
      height: 68px;
      background: #fff;
      box-shadow: 0 4px 8px 0 rgba(28, 31, 33, 0.1);

      .tab-block {
        width: 1152px;
        height: 68px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        .tab-item {
          position: relative;
          float: left;
          margin-right: 96px;
          font-size: 16px;
          color: @md-primary-color;
          line-height: 40px;
          cursor: pointer;
          font-weight: 700;

          &::before {
            content: " ";
            position: absolute;
            width: 20px;
            height: 3px;
            background: red;
            bottom: 0;
            margin-left: -10px;
            left: 50%;
            border-radius: 2px;
          }
        }
      }
    }

    .comment-intro-block {
      width: 1152px;
      margin: 20px auto 0;
      display: flex;
      background: #fff;

      .comment-box {
        flex: 1;
        margin-right: 15px;

        .c-head {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;
          box-sizing: border-box;

          .c-tag {
            padding: 4px 12px;
            font-size: 12px;
            color: #545c63;
            line-height: 16px;
            background: #fff;
            border-radius: 12px;
            cursor: pointer;
            opacity: 0;
          }

          .c-send-btn {
            padding: 7px 16px;
            font-size: 14px;
            line-height: 1.42857143;
            border-radius: 18px;
            color: #545c63;
            cursor: pointer;
            background: rgba(84, 92, 99, 0.1);
          }
        }

        .commit-list {
          margin-top: 20px;
          width: 100%;
          padding: 15px 10px 0;
          overflow-y: auto;

          .commit-item {
            // margin-bottom: 15px;
            width: 100%;
            display: flex;
            padding: 15px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #ededed;

            // margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .r-box {
              margin-left: 10px;
              flex: 1;

              .tit-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4px;

                .name {
                  font-size: 14px;
                  color: #333;
                }

                .time {
                  font-size: 12px;
                  color: #888;
                }
              }

              .tit {
                font-size: 12px;
                color: #555;
              }
            }
          }
        }
      }

      .intro-box {
        width: 328px;
        flex-shrink: 0;
        margin-bottom: 30px;

        ul {
          margin-top: 10px;
          // background: #f8fafc;
          padding: 5px;
          box-sizing: border-box;

          li {
            display: flex;
            margin-bottom: 12px;
            padding: 10px;
            border-radius: 4px;
            // border-bottom: 1px solid rgba(43,51,59,0.1);
            box-shadow: 0 4px 8px 0 rgba(95, 101, 105, 0.1);

            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            .c-img {
              width: 80px;
              height: 60px;
              border-radius: 6px;
              overflow: hidden;
              position: relative;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .c-type-box {
              flex: 1;
              height: 60px;
              margin-left: 20px;
              overflow: hidden;

              .c-tit {
                width: 100%;
                padding-top: 8px;
                font-size: 14px;
                // height: 24px;
                // line-height: 24px;
                margin-bottom: 4px;
                cursor: pointer;
                color: #545c63;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .view-p-count {
                display: flex;
                align-items: center;
                color: #545c63;
                font-size: 12px;
                margin-top: 5px;

                i {
                  margin-right: 3px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .no-more-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    i {
      font-size: 30px;
      color: #9199a1;
      margin-bottom: 5px;
    }

    span {
      font-size: 16px;
      color: #9199a1;
      line-height: 24px;
    }
  }

  @keyframes load-effect {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
.less-width {
  width: 356px !important;
  background-color: #fff !important;
  .content .content-item {
    color: #000 !important;
  }
}
.scroll-bar {
  &::-webkit-scrollbar-track {
    // border-radius: 10px;
    background-color: #efefef;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #efefef;
  }

  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    // background-color: rgba(0, 0, 0, 0.2);
    background-color: #888;
  }
}

// /deep/ .el-button--primary {
//   background: #f20d0d;
//   border-color: #f20d0d;
// }
// .pagination {
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
// }
// /deep/ .el-pager li.active {
//   color: #F20D0D;
// }
// /deep/ .el-pager li:hover {
//   color: #F20D0D;
// }
// /deep/ .el-input__inner:focus {
//   border-color: #F20D0D !important;
// }
// /deep/ .el-pagination button:hover {
//   color: #F20D0D;
// }
</style>
